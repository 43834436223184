import { usePathname } from "next/navigation";
import React from "react";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function WhatsappFlottingButton() {
  const pathname = usePathname();
  if (pathname.includes("/billets")) return null;
  return (
    <FloatingWhatsApp
      accountName="LeHubs 🚀"
      phoneNumber="+2250767764749"
      avatar="/logo.webp"
      allowEsc
      notificationSound
      notification
      chatMessage="Bonjour 👋! Comment pouvons-nous vous aider aujourd'hui ? 😊"
      placeholder="Écrivez votre message ici... ✍️"
      statusMessage="Répond généralement en quelques minutes ⏳"
      style={{
        zIndex: 9999
      }}
    />
  );
}
